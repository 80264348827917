import React, { Component, } from 'react';
import {
  bool,
  func, object, shape, string,
} from 'prop-types';

import { withApollo, } from 'react-apollo';
import {
  changeAndValidateInput,
  validateAndMergeWholeForm,
  changeFormValidations,
} from '../../../../../logic/form/common';
import { finishFormStructure, } from '../forms';
import { pipe, } from '../../../../../logic/utils';
import { withErrors, } from '../../../../../logic/errorManager/withErrors';
import { withNotifications, } from '../../../../../logic/notifications/withNotifications';
import Modal from '../../../../../atoms/Modal/Modal';
import ModalFinishView from './ModalFinishView';
import { QUERY_TRANSPORTATION_DETAIL_ADMIN, } from '../../../../Transportation/gql/queries';


class ModalFinish extends Component {
  state = {
    finishForm: { ...finishFormStructure, },
    canFinish: false,
  }

  async componentDidMount() {
    const { client, transportationId, languageId, } = this.props;

    const response = await client.query({
      query: QUERY_TRANSPORTATION_DETAIL_ADMIN,
      fetchPolicy: 'network-only',
      variables: { id: transportationId, languageId, },
    });

    const carQuantity = response.data.fetchOneTransportation.reservation.carQuantityOut;
    const transportationTypeId = parseInt(response.data.fetchOneTransportation.reservation.resTransportationType.id, 10);
    if (!([ 8, 3, 4, ].includes(transportationTypeId) && (carQuantity === null || carQuantity === ''))) {
      this.setState({ canFinish: true, });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.detailModelOpen === false) {
      this.componentDidMount();
    }
  }


  handleChangeFinishForm = (name, value) => {
    const { finishForm, } = this.state;

    switch (name) {
      case 'manualRedirect': {
        const formWithNewValidation = changeFormValidations(
          finishForm,
          [
            {
              name: 'stand',
              validation: { required: !!value, },
            },
          ],
        );

        this.setState({
          finishForm: changeAndValidateInput(formWithNewValidation, name, value),
        });
        break;
      }

      default: {
        this.setState({
          finishForm: changeAndValidateInput(finishForm, name, value),
        });
        break;
      }
    }
  }


  /**
   * Finish / Forward
   */
  handleAction = (mutationAction) => {
    const {
      finishForm,
    } = this.state;

    const newDetailForm = validateAndMergeWholeForm(finishForm);

    // check form
    if (!newDetailForm.isValid) {
      this.setState({
        finishForm: newDetailForm,
      });

    // mutate
    } else if (finishForm.values.solve) {
      this.mutationSolve(mutationAction);
    } else {
      this.mutationFinish(mutationAction);
    }
  }


  /**
   * Forward
   */
  mutationSolve = (mutationAction) => {
    const {
      finishForm: {
        values,
      },
    } = this.state;
    const {
      platformId,
      transportationId,
    } = this.props;

    const standsId = values.stand === null ? undefined : values.stand.id;

    mutationAction({
      variables: {
        platformId,
        transportationId,
        standId: values.manualRedirect ? standsId : undefined,
      },
    });
  }


  /**
   * Finish
   */
  mutationFinish = (mutationAction) => {
    const {
      finishForm: {
        values,
      },
    } = this.state;
    const {
      platformId,
      transportationId,
    } = this.props;

    const standsId = values.stand === null ? undefined : values.stand.id;

    mutationAction({
      variables: {
        platformId,
        transportationId,
        standId: values.manualRedirect ? standsId : undefined,
      },
    });
  }

  /**
   * Finish / Forward - Completed
   */
  handleActionCompleted = () => {
    const { translations, addNotification, onToggle, } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleFinishSuccess,
    });
  }


  /**
   * Finish / Forward - Error
   */
  handleActionError = (graphQLError) => {
    const { translations, translateGraphQLError, addNotification, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleFinishError,
      text: translateGraphQLError(graphQLError),
    });
  }


  render() {
    const { finishForm, canFinish, } = this.state;
    const {
      // data
      transportationId,
      languageId,
      translations,
      transportation,
      // methods
      onToggle,
      onDetail,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={onToggle}
        size="SM"
        title={translations.centralAppStorekeeper.titleModalFinish}
        disablePadding
      >
        <ModalFinishView
          // data
          transportationId={transportationId}
          transportation={transportation}
          finishForm={finishForm}
          languageId={languageId}
          translations={translations}
          canFinish={canFinish}
          // methods
          onToggle={onToggle}
          onDetail={onDetail}
          onChangeForm={this.handleChangeFinishForm}
          onAction={this.handleAction}
          onActionCompleted={this.handleActionCompleted}
          onActionError={this.handleActionError}
        />
      </Modal>
    );
  }
}


ModalFinish.propTypes = {
  // data
  platformId: string.isRequired,
  transportationId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  transportation: object.isRequired,
  client: object.isRequired,
  detailModelOpen: bool.isRequired,
  // methods
  onToggle: func.isRequired,
  translateGraphQLError: func.isRequired,
  addNotification: func.isRequired,
  onDetail: func.isRequired,
};


export default pipe(
  withErrors,
  withNotifications,
  withApollo,
)(ModalFinish);
