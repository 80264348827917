import React from 'react';
import {
  arrayOf, func, object,
} from 'prop-types';

import Item from './Item';
import StyledQueueList from './styles/StyledQueueList';

import PartInfo from '../../../../components/Parts/PartInfo';


const loadingsTypes = [ 3, 4, 6, 7, 8, ];

const QueueAll = ({
  translations,
  translationsCommon,
  queue,
  onCall,
  onDetail,
}) => {
  if (queue.length < 1) {
    return (
      <PartInfo>
        <span className="color--watermark">
          {translations.centralAppStorekeeper.infoQueueNoTransportation}
        </span>
      </PartInfo>
    );
  }

  const loadings = queue
    .filter((item) => loadingsTypes.includes(parseInt(item.transportationTypeId, 10)))
    .sort((a, b) => (new Date(a.arrivalTime).getTime() - new Date(b.arrivalTime).getTime()));
  const unloadings = queue
    .filter((item) => !loadingsTypes.includes(parseInt(item.transportationTypeId, 10)))
    .sort((a, b) => (new Date(a.arrivalTime).getTime() - new Date(b.arrivalTime).getTime()));

  return (
    <StyledQueueList>
      {loadings.map((item) => (
        <Item
          key={item.id}
          item={item}
          translations={translations}
          translationsCommon={translationsCommon}
          onCall={onCall}
          onDetail={onDetail}
        />
      ))}
      {unloadings.map((item) => (
        <Item
          key={item.id}
          item={item}
          translations={translations}
          translationsCommon={translationsCommon}
          onCall={onCall}
          onDetail={onDetail}
        />
      ))}
    </StyledQueueList>
  );
};


QueueAll.propTypes = {
  // data
  queue: arrayOf(object).isRequired,
  translationsCommon: object.isRequired,
  translations: object.isRequired,
  // functions
  onCall: func.isRequired,
  onDetail: func.isRequired,
};


export default QueueAll;
