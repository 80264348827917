import React from 'react';
import {
  object, shape, string, number, bool, func,
} from 'prop-types';

import { parseDateToHuman, } from '../../../../logic/date';
import { RESERVATION_TYPES, } from '../../../../globals';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ValueText from '../../../../atoms/Values/ValueText';
import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import FormBackground from '../../../../components/Form/FormBackground';
import RelatedTransportations from '../RelatedTransportations';
import TransportationExtraForm from '../forms/TransportationExtraForm';


const InfoView = ({
  translations,
  reservation: {
    resNo,
    resTransportationType,
    resReservationType,
    resPlatform,
    resTimeFrom,
    resTimeTo,
    resDate,
    carQuantity,
    carCompanyId,
    destinationId,
    voyage,
  },
  company: {
    compName,
    compIdentificationNumber,
    compVatNumber,
    compStreet,
    compCity,
    compZip,
    compState,
    compContactPerson,
    compEmail,
    compPhoneNumber,
  },
  driver: {
    driverName,
    driverPhoneNumber,
  },
  truck: {
    truckRegistrationNumber,
  },
  trailer: {
    trailerRegistrationNumber,
  },
  extra: {
    note,
  },
  prevTransport,
  nextTransport,
  rsTransportationConfig,
  fromClearing,
  detailForm,
  onChangeForm,
}) => {
  const parsedCarCompanyId = parseInt(carCompanyId ? carCompanyId : 0, 10);
  const parsedDestinationId = parseInt(destinationId ? destinationId : 0, 10);
  return (
    <FormBackground className="transportationDetail--form">
      <Row>
        <Col HD={8} LG={12} MD={24}>

          <fieldset>
            <legend>{translations.transportation.titleReservationForm}</legend>

            <FormRowReadOnly label={translations.form.reservationNumber}>
              <ValueText>
                {resNo || '-'}
              </ValueText>
            </FormRowReadOnly>

            <FormRowReadOnly label={translations.form.transportationType}>
              <ValueText>
                {resTransportationType ? resTransportationType.name : '-'}
              </ValueText>
            </FormRowReadOnly>

            <FormRowReadOnly label={translations.form.reservationType}>
              <ValueText>
                {resReservationType ? resReservationType.name : '-'}
              </ValueText>
            </FormRowReadOnly>

            {(resReservationType
              && resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM
            ) && (
              <FormRowReadOnly label={translations.form.platform}>
                <ValueText>
                  {resPlatform ? resPlatform.name : '-'}
                </ValueText>
              </FormRowReadOnly>
            )}

            {(resReservationType && resReservationType.id === RESERVATION_TYPES.DAY) && (
              <FormRowReadOnly label={translations.form.date}>
                <ValueText>
                  {resDate ? parseDateToHuman(new Date(resDate), false) : '-'}
                </ValueText>
              </FormRowReadOnly>
            )}

            {(resReservationType && resReservationType.id !== RESERVATION_TYPES.DAY) && (
              <>
                <FormRowReadOnly label={translations.form.timeWindow}>
                  <ValueText>
                    {`${resTimeFrom ? parseDateToHuman(new Date(resTimeFrom)) : '-'} - ${resTimeTo ? parseDateToHuman(new Date(resTimeTo)) : '-'}`}
                  </ValueText>
                </FormRowReadOnly>
              </>
            )}

            {carQuantity && (
              <FormRowReadOnly label={translations.form.carQuantity}>
                <ValueText>
                  {carQuantity}
                </ValueText>
              </FormRowReadOnly>
            )}

            {parsedCarCompanyId > 0 && (
              <FormRowReadOnly label={translations.form.carCompany}>
                <ValueText>
                  {rsTransportationConfig.carCompanies.find((carCompany) => carCompany.id === parsedCarCompanyId).name}
                </ValueText>
              </FormRowReadOnly>
            )}

            {parsedDestinationId > 0 && (
              <FormRowReadOnly label={translations.form.destination}>
                <ValueText>
                  {rsTransportationConfig.destinations.find((destination) => destination.id === parsedDestinationId).name}
                </ValueText>
              </FormRowReadOnly>
            )}

            {voyage && (
              <FormRowReadOnly label={translations.form.voyage}>
                <ValueText>
                  {voyage}
                </ValueText>
              </FormRowReadOnly>
            )}

          </fieldset>

          <RelatedTransportations
            prev={prevTransport}
            next={nextTransport}
            translations={translations}
          />

        </Col>
        <Col HD={8} LG={12} MD={24}>

          <fieldset>
            <legend>{translations.transportation.titleCompanyForm}</legend>

            <FormRowReadOnly label={translations.form.name}>
              <ValueText>
                {compName || '-'}
              </ValueText>
            </FormRowReadOnly>

            <Row>
              <Col SM={12}>
                <FormRowReadOnly label={translations.form.identificationNumber}>
                  <ValueText>
                    {compIdentificationNumber || '-'}
                  </ValueText>
                </FormRowReadOnly>
              </Col>
              <Col SM={12}>
                <FormRowReadOnly label={translations.form.vatNumber}>
                  <ValueText>
                    {compVatNumber || '-'}
                  </ValueText>
                </FormRowReadOnly>
              </Col>
            </Row>

            <FormRowReadOnly label={translations.form.street}>
              <ValueText>
                {compStreet || '-'}
              </ValueText>
            </FormRowReadOnly>

            <Row>
              <Col SM={16}>
                <FormRowReadOnly label={translations.form.city}>
                  <ValueText>
                    {compCity || '-'}
                  </ValueText>
                </FormRowReadOnly>
              </Col>
              <Col SM={8}>
                <FormRowReadOnly label={translations.form.zip}>
                  <ValueText>
                    {compZip || '-'}
                  </ValueText>
                </FormRowReadOnly>
              </Col>
            </Row>

            <FormRowReadOnly label={translations.form.state}>
              <ValueText>
                {compState || '-'}
              </ValueText>
            </FormRowReadOnly>

            <h5>{translations.form.contactPerson}</h5>

            <FormRowReadOnly>
              <ValueText>
                {compContactPerson || '-'}
              </ValueText>
            </FormRowReadOnly>

            <FormRowReadOnly label={translations.form.email}>
              <ValueText>
                {compEmail || '-'}
              </ValueText>
            </FormRowReadOnly>

            <FormRowReadOnly label={translations.form.phoneNumber}>
              <ValueText>
                {compPhoneNumber || '-'}
              </ValueText>
            </FormRowReadOnly>

          </fieldset>

        </Col>
        <Col HD={8} LG={12} MD={24}>

          <fieldset>
            <legend>{translations.transportation.titleDriverForm}</legend>

            <FormRowReadOnly label={translations.form.personName}>
              <ValueText>
                {driverName || '-'}
              </ValueText>
            </FormRowReadOnly>

            <FormRowReadOnly label={translations.form.phoneNumber}>
              <ValueText>
                {driverPhoneNumber || '-'}
              </ValueText>
            </FormRowReadOnly>

          </fieldset>

          <fieldset>
            <legend>{translations.transportation.titleTruckForm}</legend>

            <FormRowReadOnly label={translations.form.truckRegistrationNumber}>
              <ValueText>
                {truckRegistrationNumber || '-'}
              </ValueText>
            </FormRowReadOnly>

          </fieldset>

          <fieldset>
            <legend>{translations.transportation.titleTrailerForm}</legend>

            <FormRowReadOnly label={translations.form.truckRegistrationNumber}>
              <ValueText>
                {trailerRegistrationNumber || '-'}
              </ValueText>
            </FormRowReadOnly>

          </fieldset>

          <fieldset>
            <legend>{translations.transportation.titleExtraForm}</legend>

            {fromClearing && detailForm && (
              <TransportationExtraForm
                {...detailForm}
                translations={translations}
                onChange={onChangeForm}
              />
            )}

            {(!fromClearing || !detailForm) && (
              <FormRowReadOnly label={translations.form.notice}>
                <ValueText>
                  {note || '-'}
                </ValueText>
              </FormRowReadOnly>
            )}

          </fieldset>

        </Col>
      </Row>
    </FormBackground>
  );
};


InfoView.propTypes = {
  translations: object.isRequired,
  reservation: shape({
    resNo: string.isRequired,
    resTransportationType: shape({
      name: string.isRequired,
    }).isRequired,
    resReservationType: shape({
      id: string.isRequired,
      name: string.isRequired,
    }).isRequired,
    resPlatform: shape({
      name: string.isRequired,
    }),
    resTimeFrom: string,
    resTimeTo: string,
    resDate: string,
    carQuantity: number,
    carCompanyId: string,
    destinationId: string,
  }).isRequired,
  company: shape({
    compName: string.isRequired,
    compIdentificationNumber: string,
    compVatNumber: string,
    compStreet: string,
    compCity: string,
    compZip: string,
    compState: string,
    compContactPerson: string,
    compEmail: string,
    compPhoneNumber: string,
  }).isRequired,
  driver: shape({
    driverName: string,
    driverPhoneNumber: string,
  }).isRequired,
  truck: shape({
    truckRegistrationNumber: string,
  }).isRequired,
  trailer: shape({
    trailerRegistrationNumber: string,
  }).isRequired,
  prevTransport: string,
  nextTransport: string,
  rsTransportationConfig: object.isRequired,
  fromClearing: bool,
  detailForm: object,
  onChangeForm: func,
};


InfoView.defaultProps = {
  prevTransport: null,
  nextTransport: null,
  fromClearing: false,
  detailForm: {},
  onChangeForm: null,
};


export default InfoView;
