import gql from 'graphql-tag';


export const TRANSLATIONS_DASHBOARD = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            home
            stands
            storekeeper
            monitoring
            schedule
          }
          dashboard {
            planText
            monitoringText
            standText
            clearanceText
            
            stats {
              averageWaitingTimeTitle
              averageClearanceTimeTitle
              actual
              actualSystemStateTitle
              registered
              clearing
              done
              registrationMethodTitle
              automatic
              kiosk
              webkiosk
              manual
              todayPlanTitle
              planned
              remaining
              cleared
              rampLoadingTitle
              used
              free
            }
          }
        }
        shared {
          calendar {
            dayShortSu
            dayShortMo
            dayShortTu
            dayShortWe
            dayShortTh
            dayShortFr
            dayShortSa
          }
        }
      }
    }
  }
`;
