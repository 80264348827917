import React from 'react';
import {
  arrayOf, number, object, shape,
} from 'prop-types';

import StatsGridCard from './StatsGridCard';
import ColumnChart from '../../../../atoms/ColumnChart/ColumnChart';
import RegistrationMethodColumn from './RegistrationMethodColumn';

const RegistrationMethodCard = (
  { registrationMethodStats, translations, translationsCommon, }
) => (
  <StatsGridCard>
    <header>
      <span>{translations.dashboard.stats.registrationMethodTitle}</span>
    </header>
    <div className="stats--registration-method-card--body">
      <div className="stats--registration-method-card--legend">
        <span className="stats--registration-method-card--legend-row">
          <span className="stats--registration-method-card--legend-color auto" />
          {translations.dashboard.stats.automatic}
        </span>
        <span className="stats--registration-method-card--legend-row">
          <span className="stats--registration-method-card--legend-color kiosk" />
          {translations.dashboard.stats.kiosk}
        </span>
        <span className="stats--registration-method-card--legend-row">
          <span className="stats--registration-method-card--legend-color webkiosk" />
          {translations.dashboard.stats.webkiosk}
        </span>
        <span className="stats--registration-method-card--legend-row">
          <span className="stats--registration-method-card--legend-color manual" />
          {translations.dashboard.stats.manual}
        </span>
      </div>
      <div className="stats--registration-method-card--chart">
        <ColumnChart
          columns={registrationMethodStats.map(({
            automatic, kiosk, webkiosk, manual, day,
          }) => ({
            element: (
              <RegistrationMethodColumn
                automatic={automatic}
                kiosk={kiosk}
                webkiosk={webkiosk}
                manual={manual}
                total={automatic + kiosk + manual + webkiosk}
              />
            ),
            label: Object.values(translationsCommon.calendar)[day],
          }))}
        />
      </div>
    </div>
  </StatsGridCard>
);

RegistrationMethodCard.propTypes = {
  registrationMethodStats: arrayOf(shape({
    day: number.isRequired,
    automatic: number.isRequired,
    kiosk: number.isRequired,
    webkiosk: number.isRequired,
    manual: number.isRequired,
  })).isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
};

export default RegistrationMethodCard;
