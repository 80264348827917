import gql from 'graphql-tag';


/**
 * PlatformBlocks
 */
export const QUERY_HISTORY_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            history
            filter
            home
            detail
            yes
            no
            from
            to
            greaterThan
            lessThan
          }
          form {
            transportationType
            truckRegistrationNo
            trailerRegistrationNo
            no
            arrivalState
            arrivalTime
            departureTime
            reserved
            clearanceTime
            clearanceLength
            reservationType
          }
          transportation {
            historyEmpty
            thActions
            placeholderHistoryClearanceGreaterThan
            placeholderHistoryClearanceLessThan
          }
          dropDownOptions {
            arrivalStateDropDown {
              delayed
              premature
              notComing
              ok
              inactive
            }
            yesNoAllDropDown {
              yes
              no
              all
            }
            transportationReservationTypeDropDown {
              registration
              reservationTimeWindow
              reservationTimeWindowWithPlatform
            }
          }
        }
      }
    }
  }
`;


/**
 * Schedule
 */
export const QUERY_SCHEDULE_VIEW_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            schedule
            filter
            home
            detail
            from
            to
          }
          form {
            timeWindow
            no
            transportationType
            truckRegistrationNo
            trailerRegistrationNo
            platform
            arrivalTime
            carrier
            transportationType
            reservationType
            date
          }
          transportation {
            thActions
            scheduleEmpty
            titleScheduleTimelineLegend
            tabScheduleAll
            tabScheduleTimelinePlatforms
            tabScheduleTimelineTransportationTypes
            tabScheduleTableReservationDay
            btnNewTransportation
            btnApplyFilter
            labelRange
            range6Hours
            range12Hours
            range18Hours
            range24Hours
            range30Hours
            headerTimelinePlatforms
            headerTimelineTransportationType
            placeholderTransportationType
            labelReservationOnDayAproxArrivalTime
            connectedTransports {
              titleSettings
              labelAutostartAfterFinishPrev
              buttonAddNext
              titleDetail
              titlePrevTransport
              titleNextTransport
            }
          }
          dropDownOptions {
            arrivalStateDropDown {
              planned
              delayed
              premature
              notComing
              ok
              inactive
            }
            transportationReservationTypeDropDown {
              registration
              reservationTimeWindow
              reservationTimeWindowWithPlatform
            }
          }
        }
      }
    }
  }
`;


/**
 * Transportation
 */
export const TRANSLATIONS_TRANSPORTATION = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            close
            back
            create
            created
            select
            from
            to
            unit
            update
            updated
            delete
          }
          transportation {
            detailTransportationModalTitle
            titleTransportationFlow
            titleTransportationActions
            createModalTransportationTitle
            subHeaderReservation
            subHeaderVehicle
            subHeaderCargo
            infoNotSelectedReservationType
            editModalTransportationTitle
            placeholderPlatform
            transportationNotStartedYet
            titleDeleteTransportation
            textDeleteTransportation
            placeholderFilterCompany
            placeholderFilterDriver
            placeholderFilterTruck
            btnApplyFilter
            btnSaveAsNewCompany
            titleReservationForm
            titleCompanyForm
            titleDriverForm
            titleTruckForm
            titleTrailerForm
            titleExtraForm
            btnApplyFilter
            labelReservationOnDayAproxArrivalTime
            connectedTransports {
              titleSettings
              labelAutostartAfterFinishPrev
              buttonAddNext
              titleDetail
              titlePrevTransport
              titleNextTransport
            }
          }
          dropDownOptions {
            transportationReservationTypeDropDown {
              registration
              reservationTimeWindow
              reservationTimeWindowWithPlatform
            }
          }
          form {
            personName
            carrier
            driver
            recipients
            transportationType
            truckRegistrationNo
            trailerRegistrationNo
            cargoCode
            cargoWeight
            cargoWeightUnit
            cargoHeight
            cargoHeightUnit
            cargoLength
            cargoLengthUnit
            cargoPalettesCount
            reservationType
            date
            platform
            reservationNumber
            truckRegistrationNumber
            phoneNumber
            name
            contactPerson
            email
            identificationNumber
            vatNumber
            street
            city
            zip
            state
            timeWindow
            carQuantity
            carCompany
            destination
            voyage
            language
            notice
          }
        }
      }
    }
  }
`;
