import React, { Component, } from 'react';
import {
  shape, func, object,
} from 'prop-types';

import { parseTimeToHuman, } from '../../../../logic/date';
import Badge from '../../../../atoms/Badge/Badge';
import Button from '../../../../atoms/Button/Button';
import CircleWithCross from '../../../../styles/icons/CircleWithCross';


const convertServiceTypeToName = (serviceType) => {
  const index = parseInt(serviceType, 10);

  switch (index) {
    case 1: return 'unload';
    case 2: return 'load';
    case 3: return 'mixed';
    default: return '';
  }
};

class Item extends Component {
  renderClo2 = () => {
    const { item: { clearanceState, }, } = this.props;

    const clearanceStates = {
      forwarded: this.renderCol2Forwarded,
      delayed: this.renderCol2Delayed,
    };

    if (Object.prototype.hasOwnProperty.call(clearanceStates, clearanceState)) {
      return clearanceStates[clearanceState]();
    }
    return this.renderCol2Info();
  }


  renderCol2Info = () => {
    const { item, } = this.props;

    return (
      <>
        {item.customs && (
          <div className="queueList--item-text" style={{ color: 'red', fontWeight: 'bold', }}>
            K PROCLENÍ!
          </div>
        )}
        {item.blacklist && (
          <div className="queueList--item-text" style={{ color: 'red', fontWeight: 'bold', }}>
            BLACKLIST!
          </div>
        )}
        <div className="queueList--item-text">
          {item.note}
        </div>
        <div className="queueList--item-text">
          {item.reservationTypeName}
        </div>
        {(item.timeFrom && item.timeTo) && (
          <div className="queueList--item-text">
            {`${parseTimeToHuman(new Date(item.timeFrom))} - ${parseTimeToHuman(new Date(item.timeTo))}`}
          </div>
        )}
        <div className="queueList--item-text">
          {item.platformName}
        </div>
      </>
    );
  }


  renderCol2Forwarded = () => {
    const { translationsCommon, item, } = this.props;

    return (
      <>
        <Badge>
          {translationsCommon.storekeeperClearingStates.forwarded}
        </Badge>
        <div className="queueList--item-text">
          {item.note}
        </div>
      </>
    );
  }


  renderCol2Delayed = () => {
    const { translationsCommon, item, } = this.props;

    return (
      <>
        <Badge>
          {translationsCommon.storekeeperClearingStates.delayed}
        </Badge>
        <div className="queueList--item-text">
          {item.note}
        </div>
      </>
    );
  }


  render() {
    const {
      // data
      item,
      translations,
      // functions
      onCall,
      onDetail,
    } = this.props;

    const transportationTypeId = parseInt(item.transportationTypeId, 10);

    return (
      <li className="queueList--item">
        <div className="queueList--sub-item">
          <div className="queueList--item-col1">
            <div className="queueList--item-text queueList--item-text-no">
              <span>{item.driverName}</span>
              {item.notArrivedInLimit && (
                <CircleWithCross style={{ height: 'auto', marginLeft: '4px', }} />
              )}
            </div>
            <div className="queueList--item-text queueList--item-text-rest">
              {item.truckRegistration}
            </div>
            <div className="queueList--item-text queueList--item-text-rest">
              {item.transportationTypeName}
              <span className={`queueList--item-dot queueList--item-dot-${convertServiceTypeToName(item.serviceType)}`} />
            </div>
          </div>
          <div className="queueList--item-col2">
            {this.renderClo2()}
          </div>
          <div className="queueList--item-col3">
            <div>
              <Button
                className="queueList--item-btn"
                onClick={() => onCall(item)}
                size="sm"
                color="success"
                paddingHalf
              >
                {translations.centralAppStorekeeper.btnQueueCall}
              </Button>
            </div>
            <div>
              <Button
                className="queueList--item-btn queueList--item-btn-detail"
                onClick={() => onDetail(item)}
                size="sm"
                color="tertiary"
                transparent
                paddingHalf
              >
                {translations.centralAppStorekeeper.btnQueueDetail}
              </Button>
            </div>
          </div>
        </div>
        {[ 1, 2, 4, ].includes(transportationTypeId) && item.carCompany && (
          <div className="queueList--info">
            {item.carCompany && (
              <div style={{ width: '35%', }}>{item.carCompany}</div>
            )}
            {item.destination && (
              <div style={{ width: '45%', }}>{item.destination}</div>
            )}
            {item.carQuantity && (
              <div style={{ width: '20%', textAlign: 'right' }}>{item.carQuantity}</div>
            )}
          </div>
        )}
        {[ 3, ].includes(transportationTypeId) && item.voyage && (
          <div className="queueList--info">{item.voyage}</div>
        )}
      </li>
    );
  }
}


Item.propTypes = {
  // data
  item: shape({

  }).isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  // functions
  onCall: func.isRequired,
  onDetail: func.isRequired,
};


export default Item;
